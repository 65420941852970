/* Custom color theme */
:root {
  --f7-theme-color: #2c63c9;
  --f7-theme-color-rgb: 44, 99, 201;
  --f7-theme-color-shade: #2553a8;
  --f7-theme-color-tint: #4779d7;
}

/* Left Panel right border when it is visible by breakpoint */
.panel-left.panel-in-breakpoint:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

/* Hide navbar link which opens left panel when it is visible by breakpoint */
.panel-left.panel-in-breakpoint ~ .view .navbar .panel-open[data-panel="left"] {
  display: none;
}

/*
  Extra borders for main view and left panel for iOS theme when it behaves as panel (before breakpoint size)
*/
.ios .panel-left:not(.panel-in-breakpoint).panel-in ~ .view-main:before,
.ios .panel-left:not(.panel-in-breakpoint).panel-closing ~ .view-main:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}

.nav-list {
  list-style: none;
}

.nav-title {
  text-decoration: none;
  font-weight: 600;
  font-size: 16pt;
}

.nav-subtitle {
  text-decoration: none;
  font-weight: 400;
  font-size: 12pt;
}
.text-color-darkgray {
  color: #444;
}

.title-text {
  color: #444 !important;
}

.sub-title-text {
  padding-top: .5em;
  font-size: 9pt;
  color: #777;
}

.block {
  max-width: 900px;
}

